import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import FacebookButton from '../svgs/resources/FacebookButton.js';
import TwitterButton from '../svgs/resources/TwitterButton.js';
import LinkedinButton from '../svgs/resources/LinkedinButton.js';
import CopyLinkButton from '../svgs/resources/CopyLinkButton.js';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';
import '../styles/blog-post.scss';

export default function BlogPost(qlData) {
    let blogID = qlData.pathContext.id;
    let data = qlData.data;
    let currentBlog;

    for (let i = 0; i < data.allWordpressPost.edges.length; i++) {
        if (data.allWordpressPost.edges[i].node.id === blogID) {
            currentBlog = data.allWordpressPost.edges[i];
        }
    }

    const post = currentBlog.node;
    const date = new Date(post.date).toString().split(' ');
    const formatedDate = date[1] + ' ' + date[2] + ', ' + date[3];
    const category = post.categories[0].name.split(' ').join('-');
    const blogURL = 'https://glasshive.com/' + post.title.split(' ').join('-');
    const featuredImageUrl = post.featured_media
        ? post.featured_media.localFile.url
        : require('../images/resources/blog-placeholder.png');
    const copyURL = () => {
        navigator.clipboard.writeText(window.location.href);
    };
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': post.id,
        },
        headline: post.title,
        description: post.excerpt,
        image: featuredImageUrl,
        author: {
            '@type': 'Organization',
            name: post.author.name,
        },
        datePublished: post.date,
    };

    return (
        <Layout navTheme="light">
            <Seo
                schemaMarkUp={schema}
                title={post.title}
                description={post.excerpt}
                image={featuredImageUrl}
            />
            <div className="single-post">
                <div
                    className="feat-img"
                    style={{
                        backgroundImage: `url(${featuredImageUrl})`,
                    }}
                >
                    <div className="hero-text">
                        <h1>{post.title}</h1>
                        {post.acf && (
                            <p className="subtext-hook">
                                {post.acf.hero_subtext}
                            </p>
                        )}
                    </div>
                </div>
                <div className="main-content">
                    <div className="socials">
                        <FacebookShareButton url={blogURL}>
                            <FacebookButton className="facebook-button" />
                        </FacebookShareButton>
                        <TwitterShareButton url={blogURL}>
                            <TwitterButton className="twitter-button" />
                        </TwitterShareButton>
                        <LinkedinShareButton url={blogURL}>
                            <LinkedinButton className="linkedin-button" />
                        </LinkedinShareButton>
                        <button className="copyURLBtn " onClick={copyURL}>
                            <CopyLinkButton className="copy-link-button" />
                        </button>
                    </div>
                    <div className="meta">
                        <div className="meta-infos">
                            <p>
                                by{' '}
                                <span className="name">{post.author.name}</span>{' '}
                                - {formatedDate}
                            </p>
                            <p className={category.toLowerCase()}></p>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
            </div>
            <input className="vis-none siteUrl" type="text" />
        </Layout>
    );
}

export const query = graphql`
    {
        allWordpressPost {
            edges {
                node {
                    id
                    title
                    date
                    excerpt
                    content
                    slug
                    acf {
                        hero_subtext
                    }
                    featured_media {
                        localFile {
                            url
                        }
                    }
                    categories {
                        name
                    }
                    author {
                        name
                        avatar_urls {
                            wordpress_24
                        }
                    }
                }
            }
        }
    }
`;
